import React, { ReactNode } from 'react';
import styles from './PublicLayout.module.css';
import Header from './PublicHeader';

interface PublicLayoutProps {
  children: ReactNode;
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default PublicLayout;