// Function to get domain without subdomain
const getDomain = () => {
  const hostname = window.location.hostname;
  
  // Handle localhost separately
  if (hostname === 'localhost') {
    return 'localhost';
  }

  // Always use the base domain (lularoe.com) regardless of environment
  const parts = hostname.split('.');
  return parts.slice(-2).join('.');
};

export const setThemeColor = (color: string) => {
  const domain = getDomain();
  // For localhost, don't set the domain attribute
  const domainAttr = domain === 'localhost' ? '' : `domain=.${domain};`;
  document.cookie = `themeColor=${color}; path=/; ${domainAttr} max-age=31536000; SameSite=Lax`; // 1 year expiry
};

export const getThemeColor = (): string => {
  const themeColor = document.cookie
    .split('; ')
    .find(row => row.startsWith('themeColor='))
    ?.split('=')[1];
  return themeColor || '#9595D2'; // Default color if not found
}; 