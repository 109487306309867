import axios from 'axios';

interface SignInCredentials {
  email: string;
  password: string;
}

interface SignInResponse {
  challengeName?: 'NEW_PASSWORD_REQUIRED';
  session?: string;
  message: string;
}

export const signIn = async ({ email, password }: SignInCredentials): Promise<SignInResponse> => {
  try {
    const response = await axios.post<SignInResponse>(
      `${process.env.REACT_APP_API_BASE_URL}/auth/signin`,
      { email, password },
      { withCredentials: true }
    );

    if (response.data.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return response.data;
    }

    window.location.href = process.env.REACT_APP_PLATFORM_URL || '/';
    return response.data;
  } catch (error) {
    throw error;
  }
}; 