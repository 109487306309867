import axios from 'axios';

export const resetPassword = async (
  email: string,
  confirmationCode: string,
  newPassword: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`,
      {
        email,
        confirmationCode,
        newPassword
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in resetPassword API call:', error);
    throw error;
  }
};
