import React, { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './AuthSection.module.css';
import { signIn } from '../api/auth/signIn';
import { completeNewPassword } from '../api/auth/completeNewPassword';
import { forgotPassword } from '../api/auth/forgotPassword';
import { resetPassword } from '../api/auth/resetPassword';

type ViewType = 'login' | 'forgotPassword' | 'support' | 'newPasswordRequired';
type ResetStepType = 'INITIATE' | 'CONFIRM_RESET_PASSWORD_WITH_CODE';

const AuthSection: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [error, setError] = useState('');
  const [view, setView] = useState<ViewType>('login');
  const [resetStep, setResetStep] = useState<ResetStepType>('INITIATE');
  const [session, setSession] = useState<string>('');
  const [newPasswordEmail, setNewPasswordEmail] = useState<string>('');
  const navigate = useNavigate();

  const clearFormFields = () => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setResetCode('');
    setError('');
  };

  const handleViewChange = (newView: ViewType) => {
    clearFormFields();
    setResetStep('INITIATE');
    setView(newView);
  };

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    try {
      const response = await signIn({ email, password });
      
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setSession(response.session || '');
        setNewPasswordEmail(email);
        setView('newPasswordRequired');
        clearFormFields();
      }
    } catch (error) {
      setError('Failed to sign in');
    }
  };

  const handleResetPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    try {
      await forgotPassword(email);
      setResetStep('CONFIRM_RESET_PASSWORD_WITH_CODE');
    } catch (error) {
      setError('Failed to initiate password reset');
      console.error('Error resetting password:', error);
    }
  };

  const handleConfirmReset = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await resetPassword(email, resetCode, password);
      setResetStep('INITIATE');
      setView('login');
    } catch (error) {
      setError('Failed to reset password');
      console.error('Error confirming password reset:', error);
    }
  };

  const handleNewPasswordSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await completeNewPassword(newPasswordEmail, password, session);
    } catch (error) {
      setError('Failed to set new password');
      console.error('Error setting new password:', error);
    }
  };

  const renderView = () => {
    switch (view) {
      case 'login':
        return (
          <div className={styles.formContainer}>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleLogin}>
              <input
                type="email"
                placeholder="BUILD EMAIL"
                className={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="username"
              />
              <input
                type="password"
                placeholder="PASSWORD"
                className={styles.input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="current-password"
              />
              <div className={styles.buttonContainer}>
                <button className={styles.submitButton} type="submit">
                  &#10140;
                </button>
              </div>
            </form>
          </div>
        );
      case 'forgotPassword':
        if (resetStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
          return (
            <div className={styles.formContainer}>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <form onSubmit={handleConfirmReset}>
                <input
                  type="text"
                  placeholder="CONFIRMATION CODE"
                  className={styles.input}
                  value={resetCode}
                  onChange={(e) => setResetCode(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="NEW PASSWORD"
                  className={styles.input}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />
                <input
                  type="password"
                  placeholder="CONFIRM NEW PASSWORD"
                  className={styles.input}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />
                <div className={styles.buttonContainer}>
                  <button className={styles.submitButton} type="submit">
                    &#10140;
                  </button>
                </div>
              </form>
            </div>
          );
        } else {
          return (
            <div className={styles.formContainer}>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <form onSubmit={handleResetPassword}>
                <input
                  type="text"
                  placeholder="BUILD EMAIL"
                  className={styles.input}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className={styles.buttonContainer}>
                  <button className={styles.submitButton} type="submit">
                    &#10140;
                  </button>
                </div>
              </form>
            </div>
          );
        }
      case 'support':
        return (
          <div className={styles.supportText}>
            If you're having trouble logging in, please contact Home Office at{' '}
            <a className={styles.supportNumber} href="tel:951-737-7875">
              951-737-7875
            </a>{' '}
            between the hours of 7am - 4pm (Pacific Time) Monday - Friday. Our Support Offices are closed on the weekends and major holidays.
          </div>
        );
      case 'newPasswordRequired':
        return (
          <div className={styles.formContainer}>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleNewPasswordSubmit}>
              <input
                type="password"
                placeholder="NEW PASSWORD"
                className={styles.input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="new-password"
              />
              <input
                type="password"
                placeholder="CONFIRM NEW PASSWORD"
                className={styles.input}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                autoComplete="new-password"
              />
              <div className={styles.passwordRequirements}>
                <p className={styles.requirementsTitle}>Password Requirements:</p>
                <ul>
                  <li>Minimum 8 characters</li>
                  <li>At least one uppercase letter</li>
                  <li>At least one lowercase letter</li>
                  <li>At least one number</li>
                  <li>At least one special character (!@#$%^&*)</li>
                </ul>
              </div>
              <div className={styles.buttonContainer}>
                <button className={styles.submitButton} type="submit">
                  &#10140;
                </button>
              </div>
            </form>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.authContainer}>
      <div className={styles.authContainerView}>
        <div className={styles.nav}>
          <button 
            onClick={() => handleViewChange('login')} 
            className={view === 'login' ? styles.active : ''}
          >
            LOGIN
          </button>
          <button 
            onClick={() => handleViewChange('forgotPassword')} 
            className={view === 'forgotPassword' ? styles.active : ''}
          >
            FORGOT PASSWORD
          </button>
          <button 
            onClick={() => handleViewChange('support')} 
            className={view === 'support' ? styles.active : ''}
          >
            SUPPORT
          </button>
        </div>
        <div className={styles.formWrapper}>{renderView()}</div>
      </div>
    </div>
  );
};

export default AuthSection;
