import React, { useState, useEffect } from 'react';
import styles from './PublicHeader.module.css';
import confetti from 'canvas-confetti';

const Header = () => {
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    if (clickCount === 8) {
      showConfetti();
      setClickCount(0); // Reset the count after showing confetti
    }
  }, [clickCount]);

  const handleConfetti = () => {
    setClickCount(prevCount => prevCount + 1);
  };

  
  const showConfetti = () => {
    confetti({
      particleCount: 200,
      spread: 400,
      origin: { y: 0.5 },
    });
  };

  return (
    <header className={styles.header}>
      <img
        src="/images/llr_home_icon.png"
        alt="Home Icon"
        className={styles.image}
        onClick={handleConfetti}
      />
    </header>
  );
};

export default Header;