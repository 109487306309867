import axios from 'axios';

export const completeNewPassword = async (email: string, newPassword: string, session: string): Promise<void> => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/complete-new-password`,
      {
        email,
        newPassword,
        session
      },
      {
        withCredentials: true
      }
    );
    
    // If successful, redirect to platform URL
    window.location.href = process.env.REACT_APP_PLATFORM_URL || '/';
  } catch (error) {
    throw error;
  }
}; 