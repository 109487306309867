import React from 'react';
import styles from './WelcomeMessage.module.css';

const WelcomeMessage = () => {
  return (
    <div className={styles.welcomeContainer}>
      <div className={styles.line}></div>
      <h1 className={styles.title}>WELCOME TO HOME</h1>
    </div>
  );
};

export default WelcomeMessage;
