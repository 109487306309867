import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import useThemeColors from './hooks/useThemeColors';

// Public
import Landing from './pages/Landing';

function App() {
  const { isLoaded } = useThemeColors();

  if (!isLoaded) {
    return null;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Landing />} />
          {/* Catch-all route - redirects any unmatched path to landing page */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
