import { useState, useEffect } from 'react';
import { getThemeColor, setThemeColor } from '../utils/themeStorage';

const useThemeColors = () => {
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const color = getThemeColor();
    setSelectedColor(color);
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (selectedColor) {
      const calculateHoverColor = (color: string) => {
        return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.8)`;
      };

      const calculateLightColor = (color: string) => {
        return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.25)`;
      };

      document.documentElement.style.setProperty('--primary-site-color', selectedColor);
      document.documentElement.style.setProperty('--primary-site-color-hover', calculateHoverColor(selectedColor));
      document.documentElement.style.setProperty('--primary-site-color-light', calculateLightColor(selectedColor));
      setThemeColor(selectedColor);
    }
  }, [selectedColor]);

  return { selectedColor, setSelectedColor, isLoaded };
};

export default useThemeColors; 