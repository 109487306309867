import axios from 'axios';

export const forgotPassword = async (email: string) => {

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/forgot-password`,
      { email }
    );
    return response.data;
  } catch (error) {
    console.error('Error in forgotPassword API call:', error);
    throw error;
  }
};