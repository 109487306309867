import React from 'react';
import PublicLayout from '../components/PublicLayout'
import styles from './Landing.module.css';
import WelcomeMessage from '../components/WelcomeMessage';
import AuthSection from '../components/AuthSection';

const Landing = () => {
  return (
    <PublicLayout>
      <div className={styles.homeContainer}>
        <WelcomeMessage />
        <AuthSection />
      </div>
    </PublicLayout>
  );
};

export default Landing;
